import { createRouter, createWebHashHistory } from 'vue-router';
import loginRoutes from '@/modules/login';
import DashboardRoutes from '@/modules/dashboard';
import ApplicationRoutes from '@/modules/application';
import PDVRoutes from '@/modules/pdv';
import DynamicRoutes from '@/modules/dynamic';
import FinanceRoutes from '@/modules/finance';
import RegisterRoutes from '@/modules/register';
import IntegrationRoutes from '@/modules/integration';
import FiscalRoutes from '@/modules/fiscal';
import DocumentRoutes from '@/modules/document';
import AccountRoutes from '@/modules/account';
import UserRoutes from '@/modules/user';
import { useAuthStore } from '@/modules/login/modules/signIn/store';

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    component: () => import('@/ui/zweb/layouts/main-layout/AppLayout.vue'),
    meta: { middleware: 'auth' },
    children: [
      {
        path: '/',
        redirect: '/dashboard',
        component: () => import('@/ui/zweb/layouts/main-layout/MainLayout.vue'),
        children: [
          ...DashboardRoutes,
          ...FinanceRoutes,
          ...RegisterRoutes,
          ...FiscalRoutes,
          ...IntegrationRoutes,
          ...DocumentRoutes,
          ...AccountRoutes,
          ...UserRoutes,
        ],
      },
      ...PDVRoutes,
      ...DynamicRoutes,
    ],
  },
  ...loginRoutes,
  ...ApplicationRoutes,
  { path: '/:pathMatch(.*)*', redirect: '/404' },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export const getCurrentRoute = () => router?.currentRoute?.value;

router.beforeEach((to, _, next) => {
  const authStore = useAuthStore();
  authStore.setHeader();
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });

  if (to.meta.middleware == 'auth' && !authStore.isAuthenticated)
    return next({ name: 'sign-in' });

  return next();
});

export default router;
