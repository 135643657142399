/* eslint-disable @typescript-eslint/no-explicit-any */
import { type Socket, connect as connectToSocket } from 'socket.io-client';
import { eventManager } from './event-manager';
import type { ClientData } from '@/modules/account/modules/company/entities/client-data';
import type { ProfileData } from '@/modules/login/modules/signIn/entities/profile-data';

export enum SocketConnectionOrigins {
  ZetaWeb = 'ZetaWeb',
  TerminalZeta = 'Terminal Zeta',
}

export enum InfraEvents {
  JoinUser = 'join:user',
  JoinClient = 'join:client',
}

export type SocketRoomData = {
  origin: SocketConnectionOrigins;
  socketId: string;
  connectedAt: string;
};

export type JoinedEventListener = (connections: SocketRoomData[]) => any;

export type SocketMessageData = {
  action: any;
  content: any;
  id: number;
  origin: {
    description: string;
    identification: string;
    image: string;
    type: number;
  };
};

export type ClientEventAck = ((arg?: any) => void) | undefined;

let socketInstance: Socket | undefined;

const connect = () => {
  if (socketInstance !== undefined) return;

  socketInstance = connectToSocket(import.meta.env.VITE_APP_WEBSOCKET, {
    transports: ['websocket'],
  });

  socketInstance.on('connect', () => {
    eventManager.emit('websocket:connect');
  });
  socketInstance.on('reconnect', () => {
    eventManager.emit('websocket:reconnect');
  });
  socketInstance.on('disconnect', () => {
    eventManager.emit('websocket:disconnect');
  });
};

const onConnect = (listener: () => void): void => {
  socketInstance?.on('connect', listener);
};

const disconnect = () => {
  socketInstance?.disconnect();
  socketInstance = undefined;
};

const getId = () => socketInstance?.id;

const on = (event: string, listener: (...args: any[]) => void) => {
  return socketInstance?.on(event, listener);
};

const joinWithAckAndTimeout = <T>(room: string, ...args: any[]) => {
  return socketInstance
    ?.timeout(2000)
    .emitWithAck('join', room, ...args) as Promise<T>;
};

const joinToClient = async (client: ClientData) => {
  const data = {
    id: client.id,

    uuid: client.uuid,
    name: client.businessName || client.name,
    origin: SocketConnectionOrigins.ZetaWeb,
  };

  await socketInstance?.emitWithAck(InfraEvents.JoinClient, data);
};

const joinToUser = async (user: ProfileData) => {
  const data = {
    id: user.id,
    name: user.name,
    origin: SocketConnectionOrigins.ZetaWeb,
  };

  await socketInstance?.emitWithAck(InfraEvents.JoinUser, data);
};

const emit = (event: string, ...data: any) => {
  return socketInstance?.emit(event, ...data);
};

const once = (event: string, listener: () => void) => {
  return socketInstance?.once(event, listener);
};

const off = (event?: string, listener?: () => void) => {
  return socketInstance?.off(event, listener);
};

const isConnected = () => {
  return socketInstance?.connected;
};

export default {
  on,
  off,
  once,
  emit,
  getId,
  connect,
  onConnect,
  disconnect,
  joinToUser,
  isConnected,
  joinToClient,
  joinWithAckAndTimeout,
};
