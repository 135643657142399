import type { Nullable } from '@/shared/types';
import type { ReportData } from '@/modules/core';
import type { CoreZipCodeData } from '@/modules/core/entities';
import type { UnitOfMeasureData } from '@/modules/register/modules/unitOfMeasure/entities';
import type { ProductFiscalField } from '@/modules/register/modules/product/entities';
import type {
  FiscalCheckoutCollection,
  ParentModuleCollection,
  ReportsData,
} from './types';
import type {
  EmailType,
  UFData,
  CityData,
  IdAndName,
  IdAndDescription,
  UFStateData,
} from '@/modules/core/entities';
import type { CENQData } from '@/modules/fiscal/modules/cenq/entities/cenq-data';

import ApiService from '@/external/services/ApiService';
import ApiServiceV2 from '@/external/services/ApiServiceV2';

import { findArray } from '@/shared/array';
import { onlyNumbers } from '@/shared/format';
import { defineStore } from 'pinia';
import CacheService from '@/external/services/CacheService';

enum Endpoints {
  getAddressType = 'person.get-address_type',
  getLocationZipCode = 'core.get-location-zipcode',
  getActivityType = 'core.get-activity',
  getUnidadeMedidaTributavel = 'inventory.get-unidade-medida-tributavel',
  getReports = 'application.get-reports-user',
  getReportFilter = 'application.get-report-filters',
  getMenu = 'application.get-menu',
  nfseResponsibleRetention = 'consumers.find-summary-nfse-responsible-retention',
  nfseIssExibility = 'consumers.find-summary-iss-exibility',
  getUfs = 'person.get-uf',
  getCities = 'core.get-city',
  getCheckout = 'fiscal.get-checkout',
  getMotDesoneracao = 'fiscal.get-motdesicms',
  getVehicleWheeledType = 'consumers.mdfe-get-vehicle-wheeled-types',
  getVehicleBodyType = 'consumers.mdfe-get-vehicle-body-types',
  getState = 'core.get-state',
  getProductType = 'inventory.get-product-types',
  getCenqCollection = 'fiscal.get-cenq',
  getInternationalTransport = 'fiscal.get-via-transporte-internacional',
  getImportationWays = 'fiscal.get-forma-intermediacao',
  getIppt = 'fiscal.get-ippt',
  getIat = 'fiscal.get-iat',
}

export default defineStore('useCached', {
  state: () => ({
    modules: [] as ParentModuleCollection,
    ieTypeCollections: [
      { id: 1, name: 'Contribuinte' },
      { id: 2, name: 'Isento' },
      { id: 9, name: 'Não Contribuinte' },
    ] as IdAndName[],
    emailTypeCollections: [
      { id: 1, name: 'Principal' },
      { id: 2, name: 'Financeiro' },
      { id: 3, name: 'Adicional' },
      { id: 4, name: 'NF-e' },
    ] as EmailType[],
    contactTypeCollections: [
      { id: 1, name: 'Correio' },
      { id: 2, name: 'Email' },
      { id: 3, name: 'Em pessoa' },
      { id: 4, name: 'Fax' },
      { id: 5, name: 'Telefone' },
    ] as IdAndName[],
    contactStatusCollections: [
      { id: 1, name: 'Agendado' },
      { id: 2, name: 'Feito' },
    ] as IdAndName[],
    unitOfMeasureConvertOperationCollections: [
      { id: 1, name: 'Multiplicação' },
      { id: 2, name: 'Divisão' },
    ] as IdAndName[],
    activityTypeCollections: [] as IdAndName[],
    addressTypeCollections: [] as IdAndName[],
    unidadeMedidaTributavelCollections: [] as ProductFiscalField[],
    reportsCollection: [] as ReportsData[],
    issExibility: [],
    responsibleRentention: [],
    ufCollection: [] as UFData[],
    cityCollection: [] as CityData[],
    getParameterizationsTypes: [] as IdAndName[],
    getParameterizationsStatus: [] as IdAndName[],
    parameterizationsTypeCollections: [] as IdAndName[],
    parameterizationsStatusCollections: [] as IdAndName[],
    fiscalCheckoutCollection: [] as FiscalCheckoutCollection,
    motDesoneracaoCollection: [] as IdAndDescription[],
    vehicleWheeledType: [] as IdAndDescription[],
    vehicleBodyType: [] as IdAndDescription[],
    ufs: [] as IdAndDescription[],
    stateCollection: [] as UFStateData[],
    productTypeCollections: [] as IdAndName[],
    cenqCollection: [] as [] | CENQData[],
    internationalTransportCollection: [] as IdAndDescription[],
    importationWaysCollection: [] as IdAndDescription[],
    ipptCollection: [] as ProductFiscalField[],
    iatCollection: [] as ProductFiscalField[],
  }),
  actions: {
    async getMenu() {
      if (this.modules.length) {
        return this.modules;
      }

      const params = { version: 'v2' };
      const { data } = await ApiService.post(Endpoints.getMenu, params);
      return (this.modules = data);
    },
    async getCityCollection(uf?: string): Promise<CityData[]> {
      const params = uf ? { isUF: true, search: uf } : null;

      const { data } = await ApiService.post(Endpoints.getCities, params);

      return data as CityData[];
    },
    async getUfCollection(): Promise<UFData[]> {
      if (this.ufCollection.length) return this.ufCollection;

      const { data } = await ApiService.postWithCache(
        Endpoints.getUfs,
        {},
        CacheService.buildKey(Endpoints.getUfs)
      );

      this.ufCollection = data;

      return this.ufCollection;
    },
    async getIssExibility() {
      if (this.issExibility.length) return this.issExibility;

      const { data } = await ApiService.post(Endpoints.nfseIssExibility);

      this.issExibility = data;

      return this.issExibility;
    },
    async getResponsibleRetention() {
      if (this.responsibleRentention.length) return this.responsibleRentention;

      const { data } = await ApiService.post(
        Endpoints.nfseResponsibleRetention
      );

      this.responsibleRentention = data;

      return this.responsibleRentention;
    },
    async getReports() {
      if (this.reportsCollection.length) return this.reportsCollection;
      const { data } = await ApiServiceV2.postWithCache(
        Endpoints.getReports,
        undefined,
        CacheService.buildKey(Endpoints.getReports)
      );
      this.reportsCollection = data;
    },
    async getReportsFilter(report: ReportData) {
      const { data } = await ApiServiceV2.postWithCache(
        Endpoints.getReportFilter,
        {
          module: report.module,
          report: report.id,
        },
        CacheService.buildKey(`report.${report.module}.${report.id}`)
      );
      return data;
    },
    getReportsByModule(module: string) {
      return findArray(this.reportsCollection, 'id', module);
    },
    getIeTypes(id: Nullable<number> = null) {
      return findArray(this.ieTypeCollections, 'id', id);
    },
    getContactTypes(id: Nullable<number> = null) {
      return findArray(this.contactTypeCollections, 'id', id);
    },
    getContactStatus(id: Nullable<number> = null) {
      return findArray(this.contactStatusCollections, 'id', id);
    },
    getEmailTypes(id: Nullable<number> = null) {
      return findArray(this.emailTypeCollections, 'id', id);
    },

    async getAddressTypes() {
      if (this.addressTypeCollections.length) return;

      const { data } = await ApiService.postWithCache(
        Endpoints.getAddressType,
        {},
        CacheService.buildKey(Endpoints.getAddressType)
      );

      if (!data) return;

      this.addressTypeCollections = data as IdAndName[];
    },
    getActivityTypes(id: number | null = null) {
      if (!this.activityTypeCollections.length) {
        ApiService.postWithCache(
          Endpoints.getActivityType,
          {},
          CacheService.buildKey(Endpoints.getActivityType)
        ).then((response) => {
          this.activityTypeCollections = response.data;
        });
      }

      return findArray(this.activityTypeCollections, 'id', id);
    },
    async getLocationZipCode(zip_code: string): Promise<CoreZipCodeData> {
      const { data } = await ApiService.post(Endpoints.getLocationZipCode, {
        zip_code: onlyNumbers(zip_code),
      });

      return data;
    },
    getUnitOfMeasureConvertOperationCollections(id: number | null = null) {
      if (id == null) return this.unitOfMeasureConvertOperationCollections;

      return findArray(
        this.unitOfMeasureConvertOperationCollections,
        'id',
        id
      ) as UnitOfMeasureData;
    },
    async getUnidadeMedidaTributavel(id: number | null = null) {
      if (!this.unidadeMedidaTributavelCollections.length) {
        const response = await ApiService.post(
          Endpoints.getUnidadeMedidaTributavel,
          {}
        );

        this.unidadeMedidaTributavelCollections = response.data;
      }

      return findArray(this.unidadeMedidaTributavelCollections, 'id', id);
    },
    async getCenqCollection() {
      if (this.cenqCollection.length) return;

      const { data } = await ApiService.postWithCache(
        Endpoints.getCenqCollection,
        {
          active: true,
          search: '',
        },
        CacheService.buildKey(Endpoints.getCenqCollection)
      );

      if (!data) return;

      this.cenqCollection = data;
    },
    async getCheckoutCollection() {
      if (!this.fiscalCheckoutCollection.length) {
        const { data } = await ApiService.post(Endpoints.getCheckout, {
          status: true,
        });

        return (this.fiscalCheckoutCollection =
          data as FiscalCheckoutCollection);
      }
      return this.fiscalCheckoutCollection;
    },
    async getMotDesoneracaoCollection() {
      if (!this.motDesoneracaoCollection.length) {
        const { data } = await ApiService.post(Endpoints.getMotDesoneracao);

        return (this.motDesoneracaoCollection = data as IdAndDescription[]);
      }

      return this.motDesoneracaoCollection;
    },
    async getVehicleWheeledType() {
      if (this.vehicleWheeledType.length) return this.vehicleWheeledType;

      const { data } = await ApiService.post(Endpoints.getVehicleWheeledType);

      this.vehicleWheeledType = data;

      return this.vehicleWheeledType;
    },
    async getVehicleBodyType() {
      if (this.vehicleBodyType.length) return this.vehicleBodyType;

      const { data } = await ApiService.post(Endpoints.getVehicleBodyType);

      this.vehicleBodyType = data;

      return this.vehicleBodyType;
    },
    async getState() {
      if (!this.stateCollection.length) {
        const { data } = await ApiService.postWithCache(
          Endpoints.getState,
          undefined,
          CacheService.buildKey(Endpoints.getState)
        );

        return (this.stateCollection = data as UFStateData[]);
      }

      return this.stateCollection;
    },
    async getProductTypes() {
      if (!this.productTypeCollections.length) {
        const { data } = await ApiService.postWithCache(
          Endpoints.getProductType,
          undefined,
          CacheService.buildKey(Endpoints.getProductType)
        );

        return (this.productTypeCollections = data);
      }
    },
    async getInternationalTransportCollection() {
      if (!this.internationalTransportCollection.length) {
        const { data } = await ApiService.post(
          Endpoints.getInternationalTransport
        );

        this.internationalTransportCollection = data;

        return data;
      }

      return this.internationalTransportCollection;
    },
    async getImportationWays() {
      if (this.importationWaysCollection.length === 0) {
        const { data } = await ApiService.post(Endpoints.getImportationWays);

        this.importationWaysCollection = data;

        return data;
      }

      return this.importationWaysCollection;
    },
    async getIpptCollection() {
      if (this.ipptCollection.length === 0) {
        const { data } = await ApiService.postWithCache(
          Endpoints.getIppt,
          {},
          CacheService.buildKey(Endpoints.getIppt)
        );

        this.ipptCollection = data;

        return data;
      }

      return this.ipptCollection;
    },
    async getIatCollection() {
      if (this.iatCollection.length === 0) {
        const { data } = await ApiService.postWithCache(
          Endpoints.getIat,
          {},
          CacheService.buildKey(Endpoints.getIat)
        );

        this.iatCollection = data;

        return data;
      }

      return this.iatCollection;
    },
  },
});
